html,
body,
#root {
  height: 100%;
}

#aws-chat-widget {
  position: fixed;
  display: flex;
  bottom: 4.688rem;
  right: 0.5rem;
  z-index: 5000;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-nav-item {
  /* Animations specific to collapsed mode */
  &:not(.expanded):not(.expanding):not(.collapsing) {
    &:hover,
    &:focus-within,
    &.selected {
      /* When the nav item is hovered, focused, or selected, the color block fills the whole nav item */
      .animated-background {
        height: 100%;
        width: 100%;
        transform: translateX(0);
        border-radius: 0;
        transition:
            height 0.1s linear,
            border-radius 0.1s linear,
            width 0.2s linear 0.1s,
            transform 0.2s linear 0.1s;
      }

      /* Icon/logo color changes happen while the color block is changing */
      .sicon,
      svg {
        transition: color 0.3s linear;
      }
    }

    &:not(:hover):not(:focus-within):not(.selected) {
      /* If the nav item is not active, wait 100ms for the kebab to shrink, then shrink the width of the color block,
      then shrink its height to return it to the default "notch" state */
      .animated-background {
        transition:
          transform 0.2s linear 0.1s,
          width 0.2s linear 0.1s,
          height 0.1s linear 0.3s,
          border-radius 0.1s linear 0.3s,
          opacity 0.2s linear;
      }

      /* Icon/logo color changes happen while the color block is changing */
      .sicon,
      svg {
        transition: color 0.3s linear 0.1s;
      }
    }
  }

  /* Animations specific to expanded mode */
  &.expanded {
    &:hover,
    &:focus-within,
    &.selected {
      /* When the nav item is hovered, focused, selected, slide the color block over the icon over 400ms. If the color
      block is marked as expanded-full-fill, fill the whole nav item over 400ms */
      .animated-background {
        transform: translateX(0.75rem);
        transition: transform 0.4s linear;

        &.expanded-full-fill {
          height: 100%;
          width: 100%;
          transform: translateX(0);
          border-radius: 0;
          opacity: 0;
          transition:
            height 0.1s linear,
            border-radius 0.1s linear,
            width 0.3s linear 0.1s,
            transform 0.3s linear 0.1s,
            opacity 0.2s linear;
        }
      }
    }

    &:not(:hover):not(:focus-within):not(.selected) {
      /* When the nav item comes off hover/focus-within/selected, slide the color block back to the notch state over 400ms */
      .animated-background {
        transition: transform 0.4s linear;

        &.expanded-full-fill {
          transition:
            transform 0.3s linear,
            width 0.3s linear,
            border-radius 0.1s linear 0.3s,
            height 0.1s linear 0.3s
        }
      }
    }
  }

  /* Icon/logo color changes happen while the color block is changing */
  .sicon,
  svg {
    transition: color 0.4s linear;
  }
}
